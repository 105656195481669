import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
 const [data,setData ] = useState([])
 useEffect(()=>{
getData();
 },[]);
 const getData = ()=>{
  // const response = await axios.get('http://127.0.0.1:8000/users');
  // const data = response.data;
  // console.log('data: ',data);
  fetch('users.php').then(e=>e.json()).then(e=>setData(e));
  
 }
  return (
    
    <div className="App">
      <p>{data.length}</p>
    <table style={{padding:'5px',border:'1px solid black'}}>
      <thead className='p-2'><tr><th>نام</th><th>سرمایه اولیه</th><th>کارمزد</th></tr></thead>
    {[...data].map((e,index)=><tr><td style={{color:'black'}}>{e.name}</td><td>{e.initial_money}</td><td>{e.commision}</td></tr>)}
    </table>
      
    </div>
  );
}

export default App;
